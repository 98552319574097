<template>
  <div>
    <img src="../../assets/img/remind/remind.png" alt="" />
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>